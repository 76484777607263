import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import styles from './styles.module.css';

class QuoteLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const layout = props.section.styles.layout ? props.section.styles.layout : 'left';
    const full = props.section.styles.full === true;

    this.state = {
      align,
      layout,
      full,
    };
  }

  render() {
    const paragraphAlignStyle = `quoteParagraph${this.state.align}`;
    const quoteExceptionStyle = `quoteException${this.props.themeData.typography.default.fontSize}`;
    const quoteAuthorExceptionStyle = `quoteAuthorException${this.props.themeData.typography.default.fontSize}`;

    const texts = [];
    this.props.section.data.forEach((item, index) => {
      if (item.active) {
        let text;
        if (item.type.startsWith('PARAGRAPH')) {
          if (index === 0) {
            text = <span className={styles[quoteExceptionStyle]}>{HTMLParser(item.text)}</span>;
          } else if (index === 1) {
            text = <span className={styles[quoteAuthorExceptionStyle]}>{HTMLParser(item.text)}</span>;
          }
        }

        if (text) {
          const content = (
            <div key={`${this.props.section._id}_layout_${index}`} className={styles[paragraphAlignStyle]}>
              {text}
            </div>
          );

          texts.push(content);
        }
      }

      return null;
    });

    const columns = this.state.layout === 'left' ? 'col-12 col-sm-10 offset-sm-1' : 'col-12 col-sm-6';
    const rowWrapper = this.state.layout === 'left' ? '' : `quoteRow${this.state.align}`;
    const container =
      this.state.layout === 'left' || (this.state.layout !== 'left' && !this.state.full)
        ? 'container'
        : 'container-fluid';

    const quote =
      this.state.layout === 'left' ? (
        <div className={columns}>{texts}</div>
      ) : (
        <div
          className={`${columns} ${styles.quoteWrapper}`}
          style={this.state.layout === 'newLeft' ? this.props.boxStyle : undefined}
        >
          <div className={styles.quoteContentWrapper}>{texts}</div>
        </div>
      );

    return (
      <div className={container}>
        <div className={clsx('row', styles[rowWrapper])}>{quote}</div>
      </div>
    );
  }
}

export default QuoteLayout;
